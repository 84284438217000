import React from "react"

import { useStaticQuery, graphql } from "gatsby"
export const GoogleData = () => {
  const data = useStaticQuery(graphql`
    query {
      allGoogleIndustryCallforEquityCommitmentsResponsesSheet {
        edges {
          node {
            timestamp
            firstName
            lastName
            role_Title
            company
          }
        }
      }
    }
  `)
  const raw = data.allGoogleIndustryCallforEquityCommitmentsResponsesSheet.edges.map(
    edge => {
      return edge.node
    }
  )
  return raw
}
