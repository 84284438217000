import React from "react"
import { Link, graphql } from "gatsby"
import { Container, Row, Col, CardDeck, Card, Badge } from "react-bootstrap"
import Img from "gatsby-image"
import { GoogleData } from "../components/dataSource"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SignUpComponent from "../components/sign-up"

const IndexPage = ({ location, data }) => {
  const raw = GoogleData()

  const signatories = raw.reduce(function (r, a) {
    r[a.company] = r[a.company] || []
    r[a.company].push(a)
    return r
  }, Object.create(null))

  const sorted = Object.keys(signatories).sort(function (a, b) {
    var textA = a.toUpperCase()
    var textB = b.toUpperCase()
    return textA < textB ? -1 : textA > textB ? 1 : 0
  })

  return (
    <Layout>
      <SEO title="Home" location={location} />
      <section className="">
        <Container className="py-4 py-lg-5">
          <Row className="d-flex justify-content-center">
            <Col md="6" className="text-center">
              <Img className="mb-4" fluid={data.pocamLogo.childImageSharp.fluid} />

              <p className="lead">
                We exist to ensure the voice, vision and talent of BIPOC professionals are fully and fairly present in Canadian Advertising
                and Marketing.
              </p>
              <a className="btn btn-primary btn-lg mb-0" href="https://www.linkedin.com/groups/13724180/" target="_blank" rel="noreferrer">
                join the <strong>POCAM</strong> LinkedIn Group
              </a>
              <p className="mb-2">
                <small>Please only request to join if you identify as BIPOC.</small>
              </p>
              <p>
                or follow our{" "}
                <a href="https://www.linkedin.com/company/74187918/" target="_blank" rel="noreferrer">
                  LinkedIn Page
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-dark">
        <Container className="py-4 py-lg-5">
          <Row className="mb-5 d-flex justify-content-center">
            <CardDeck>
              <Card>
                <Card.Body>
                  <h2 className="h3 mb-3">
                    <Badge className="badge-lg py-2" variant="primary">
                      {sorted.length}
                    </Badge>{" "}
                    Canadian organizations have signed
                  </h2>
                  <p>
                    If you are an industry leader in an agency or in a client marketing department, that is committed to creating equity in
                    the industry, we ask you to please make your organization's commitment public by filling in the fields below the list of
                    demands. For transparency, all signatories will be made public.
                  </p>
                  <Link to="/call-for-equity" className="d-inline-block">
                    View the Committed Agencies
                  </Link>
                </Card.Body>
                <Card.Footer>
                  <a
                    className="btn btn-primary d-inline-block"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfPtVY0Ew6Cgyhzriv4Op4tnWiZ8B9K6ZHLyDjLfpuOYAqZHA/viewform"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Commit to the Call for Equity
                  </a>
                </Card.Footer>
              </Card>

              <Card>
                <Card.Body>
                  <h2 className="h3 mb-3">The Call for Equity Progress Tracker</h2>

                  <p>
                    POCAM is pleased to announce the release of the Call for Equity Progress Tracker—an online tool that allows agency and
                    client leaders to report, self-monitor and stay updated on industry progress made towards the Call for Equity’s 12
                    commitments (15 for client organizations).
                  </p>
                  
                  
                </Card.Body>
                <Card.Footer>
                  <Link className="btn btn-primary d-inline-block" to="/update-status">
                    Access the Call for Equity Progress Tracker
                  </Link>
                </Card.Footer>
              </Card>
            </CardDeck>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="py-4 py-lg-5">
          <Row className="mb-5 d-flex justify-content-center">
            <Col md="7">
              <h2>News & Updates</h2>
              
              <hr />
              <div className="py-3">
                <h3 className="h4">Beyond Tropes, Tokenism and Talk</h3>
                <p>April 24th, 2024</p>
                <p>Michelle Lee, Justin Senior, Aleena Mazhar and Gavin Barrett bring over 150 participants and attendees together to reimagine the strategist’s toolkit to be more inclusive of the BIPOC community.</p>

                <Link className="btn btn-primary d-inline-block" to="/events/beyond-tropes-tokenism-and-talk-recap/">
                  Read & Download
                </Link>
              </div>
              <hr />
              <div className="py-3">
                <h3 className="h4">Visible & Vocal - The Canadian BIPOC Advertising & Marketing Study</h3>
                <p>February 4th, 2021</p>
                <p>
                  Chasson Gracie, Julian Franklin, and Joshua Richards take the over 350 attendees through the data collected from the
                  inaugural Visible & Vocal study.
                </p>

                <Link className="btn btn-primary d-inline-block" to="/visible-and-vocal">
                  Watch & Download
                </Link>
              </div>
              <hr />
              <div className="py-3">
                <h3 className="h4">The Garden: 2020 The Game</h3>
                <p>October 23, 2020</p>
                <p>Joshua Richards shares some thoughts on the The Garden Agency, the industry and the opportunity for change.</p>
                <blockquote className="blockquote">
                  <p className="mb-0">
                    And where are the public voices of the creators? You are all seniors in this industry, some of you posted black squares
                    and BLM donation receipts, some of you are teachers at the Ad schools we recruit from, you have a responsibility here.
                    You can't take credit when you think it's an award winning idea, but be silent when it's causing pain.
                  </p>
                </blockquote>
                <a
                  className="btn btn-primary mr-3"
                  href="https://www.linkedin.com/pulse/garden-agency-2020-game-joshua-richards-he-him-/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Read the LinkedIn article
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <SignUpComponent />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    pocamLogo: file(relativePath: { eq: "pocam-logo-red.png" }) {
      childImageSharp {
        fluid(maxWidth: 630) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
